@import "assets/styles/_variables.scss";
@import "assets/styles/_mat-theme.scss";
@import "assets/styles/_glide.scss";
@import "assets/styles/_btn-loader.scss";
// @import "assets/styles/_loaders.scss";

body {
  margin: 0;
  font-family: var(--primary-font-family);
}

* {
  color: var(--primary-text-color);
  -webkit-overflow-scrolling:initial
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

span,
strong,
em {
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

img {
  width: inherit;
  height: auto;
  border-radius: inherit;
}


a:hover,
a:focus,
button,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
}
button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: normal;
  font-family: var(--primary-font-family);
  span {
    color: inherit;
  }
}
button, [type="button"], [type="reset"], [type="submit"]{
  -webkit-appearance: none !important;
}

input {
  outline: none;
  border: none;
  background: transparent;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, .25);
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, .05);
}


.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

.mat-mdc-menu-panel {
  min-height: 64px;
  max-height: calc(100vh - 48px);
  background-color: white !important;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;
  border-radius: 4px !important;
  .mat-mdc-menu-item {
    min-height: 30px;
    background: transparent;
    color: #000000de;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    :hover {
      color: var(--primary-color);
    }
    &.active {
      background-color: var(--primary-color-shadow-1);
    }
  }
}

.mdc-snackbar {
  .mat-mdc-snackbar-surface {
    .mat-mdc-snack-bar-label {
      color: #fff;
    }
  }
  &.snackbar-error {
    .mat-mdc-snack-bar-actions {
      .mat-mdc-snack-bar-action {
        --mat-snack-bar-button-color: #e82943;
      }
    }
  }
}

.skeleton {
  min-height: 30px;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100% !important;
  animation: loading 1.5s infinite;
  border-radius: 4px;
  border: none !important;
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.read-only,
.prevent-click {
  pointer-events: none;
}

.d-none {
  display: none;
}


@media only screen and (min-width: 767px) {
  .mobile-only {
    display: none !important;
  }
}


@media only screen and (max-width: 767px) {
  .desk-only {
    display: none !important;
  }
}

.organiser-sheet {
  .mat-bottom-sheet-container {
    padding: 0;
    border-radius: 16px 16px 0px 0px;
  }
}

.share-mob-sheet {
  .mat-bottom-sheet-container {
    padding: 0;
    border-radius: 16px 16px 0px 0px;
  }
}

.report-sheet {
  .mat-bottom-sheet-container {
    padding: 0;
    border-radius: 16px 16px 0px 0px;
    overflow-x: hidden;
  }
  .grecaptcha-badge {
    display: none;
  }
}

.contact-sheet {
  .mat-bottom-sheet-container{
    padding: 0;
    border-radius: 16px 16px 0px 0px;
  }
}


.pswp__img {
  object-fit: contain !important;
}
.w-100 {
  width: 100%;
}