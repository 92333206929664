@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

:root {
  --primary-font-family: 'DM Sans', sans-serif;
  --font-source-kalam: 'Kalam';
  --font-source-sans-pro: 'Source Sans Pro', sans-serif;
  --font-source-serif-pro: 'Source Serif 4', sans-serif;
  --rboto-font-family: 'Roboto', sans-serif;
  --Blue-blue-4: #2185E4;
  --primary-color: #2A7AAC;
  --primary-text-color: #444;
  --primary-color-rgb: 42, 122, 172;
  --primary-color-shadow-05: rgb(var(--primary-color-rgb), 0.05);
  --primary-color-shadow-1: rgb(var(--primary-color-rgb), 0.1);
  --primary-color-shadow-2: rgb(var(--primary-color-rgb), 0.2);
  --primary-color-shadow-3: rgb(var(--primary-color-rgb), 0.3);
  --primary-color-shadow-4: rgb(var(--primary-color-rgb), 0.4);
  --primary-color-shadow-5: rgb(var(--primary-color-rgb), 0.5);
  --primary-color-shadow-6: rgb(var(--primary-color-rgb), 0.6);
  --primary-color-shadow-7: rgb(var(--primary-color-rgb), 0.7);
  --primary-color-shadow-8: rgb(var(--primary-color-rgb), 0.8);
  --primary-color-shadow-9: rgb(var(--primary-color-rgb), 0.9);
  --Neutral-Black-1: #F3F3F3;
  --Neutral-Black-6: #262626;
  --Neutral-Black-5: #404040;
  --Neutral-Black-4: #737373;
  --Neutral-Black-3: #A6A6A6;
  --Neutral-Black-2: #CCCCCC;
  --Neutral-White: #FCFCFC;
  --secondary-color: #FFBE62;
  --secondary-text-color: #2C2C2C;
  --secondary-color-rgb: 255, 190, 98;
  --Gray-gray-5: #D9D9D9;
  --Gray-gray-7: #8C8C8C;
  --secondary-color-shadow-05: rgb(var(--secondary-color-rgb), 0.05);
  --secondary-color-shadow-1: rgb(var(--secondary-color-rgb), 0.1);
  --secondary-color-shadow-2: rgb(var(--secondary-color-rgb), 0.2);
  --secondary-color-shadow-3: rgb(var(--secondary-color-rgb), 0.3);
  --secondary-color-shadow-4: rgb(var(--secondary-color-rgb), 0.4);
  --secondary-color-shadow-5: rgb(var(--secondary-color-rgb), 0.5);
  --secondary-color-shadow-6: rgb(var(--secondary-color-rgb), 0.6);
  --secondary-color-shadow-7: rgb(var(--secondary-color-rgb), 0.7);
  --secondary-color-shadow-8: rgb(var(--secondary-color-rgb), 0.8);
  --secondary-color-shadow-9: rgb(var(--secondary-color-rgb), 0.9);
  --white: #FFF;
  --error-color: #ea4335;
}


