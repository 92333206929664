// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mat-equalallcolor: (
  50 : var(--primary-color-shadow-1),
  100 : var(--primary-color-shadow-2),
  200 : var(--primary-color-shadow-3),
  300 : var(--primary-color-shadow-4),
  400 : var(--primary-color-shadow-5),
  500 : var(--primary-color-shadow-6),
  600 : var(--primary-color-shadow-7),
  700 : var(--primary-color-shadow-8),
  800 : var(--primary-color-shadow-9),
  900 : var(--primary-color),
  A100 : var(--primary-color-shadow-2),
  A200 : var(--primary-color-shadow-3),
  A400 : var(--primary-color-shadow-5),
  A700 : var(--primary-color-shadow-8),
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #000000,
    500 : #ffffff,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-app-primary: mat.define-palette($mat-equalallcolor, 900);
$my-app-accent: mat.define-palette(mat.$indigo-palette);

// The warn palette is optional (defaults to red).
$my-app-warn: mat.define-palette(mat.$red-palette);

//custom styles
$my-typography: mat.define-typography-config($font-family: "'DM Sans', sans-serif;");

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$my-app-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn,
  ),
  typography: $my-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-app-theme);

:root {
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-checkbox-selected-icon-color: var(--primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color);
}

.mat-mdc-checkbox {
  .mdc-form-field {
    align-items: center;
    gap: 8px;
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-radius: 4px;
        .mdc-checkbox__checkmark {
          width: 85%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
      &.mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border: 0.5px solid #CCC;
        border-color: #CCC;
      }
      .mdc-checkbox__native-control:checked~.mdc-checkbox__background {
        .mdc-checkbox__checkmark-path {
          color: var(--Neutral-White);
        }
      }
      .mdc-checkbox__ripple,
      .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
        background-color: var(--Neutral-White);
        border-radius: 4px;
      }
    }
    .mdc-label {
      padding: 0;
    }
  }
}

.mat-accordion {
  .mat-expansion-panel {
    margin-top: 0;
    .mat-expanded {
      margin-top: 0;
    }
  }
}

.mdc-dialog {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      overflow: visible;
      border-radius: 16px;
      background-color: transparent;
    }
  }
}
