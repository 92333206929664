@import '../../../node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import '../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme';
// Overriding glide css
.glide * {
  box-sizing: unset !important;
}
.glide__slides {
  white-space: inherit !important;
}
.glide__arrow {
  height: 68px;
  width: 68px;
  border: 0;
  box-shadow: none;
  padding: 0;
  font-size: 65px !important;
  color: var(--primary-color);
}
.glide__arrow--left {
  left: -80px;
  &:after{
    font-family: "Material Icons";
    content: "\e408";
  }
}
.glide__arrow--right {
  right: -80px;
  &:after{
    font-family: "Material Icons";
    content: "\e409";
  }
}
.cards .glide__slides{
  padding-bottom: 20px !important;
}

@media only screen and (max-width: 768px) {
  .glide{
    overflow: hidden;
  }
}
